<template>
  <a-card :bordered="false" size="small" style="height: 240px">
    <template slot="title">库存趋势图</template>
    <template slot="extra">
      <a-space style="margin: -2px">
        <a-range-picker
          v-model="dateRange"
          :mode="['month', 'month']"
          :allowClear="false"
          @change="getData()"
          size="small"
          style="width: 200px"
        />
        <a-radio-group v-model="statsType" size="small" button-style="solid" @change="changeStatsType">
          <a-radio-button value="quantity">数量</a-radio-button>
          <a-radio-button value="amount">金额</a-radio-button>
        </a-radio-group>
      </a-space>
    </template>
    <div ref="columnChart"></div>
  </a-card>
</template>

<script>
import { inventoryTrend } from "@/api/stats";
import { Column } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      statsType: "quantity",
      columnChart: null,
      dateRange: [moment().subtract(12, "month").startOf("month"), moment().startOf("month")],
    };
  },
  methods: {
    initData() {
      this.columnChart = new Column(this.$refs.columnChart, {
        data: [],
        xField: "date",
        yField: "total_quantity",
        seriesField: "category",
        height: 160,
        meta: {
          total_quantity: {
            alias: "数量",
          },
          total_amount: {
            alias: "金额",
          },
        },
        label: {
          position: "middle",
        },
      });

      this.columnChart.render();
      this.getData();
    },
    getData() {
      const searchForm = {
        start_date: this.dateRange[0].format("YYYY-MM-DD"),
        end_date: this.dateRange[1].format("YYYY-MM-DD"),
      };
      inventoryTrend(searchForm).then((data) => {
        this.columnChart.changeData(data);
      });
    },
    changeStatsType(event) {
      if (event.target.value == "quantity") {
        this.columnChart.update({ yField: "total_quantity" });
      } else {
        this.columnChart.update({ yField: "total_amount" });
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
